@use 'sass:math';

@mixin l-mobile {
  @media all and (max-width: 767px) {
    @content;
  }
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
